.changes {
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid lightgray;
  border-radius: 5px;
}
.changes p {
  padding: 0.3rem 1rem;
}
.changes p:nth-child(even) {
  background-color: #f0f0f0;
}
