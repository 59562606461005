.toggle-map {
  position: absolute;
  z-index: 999999;
  top: 0;
  right: 0;
  background: white;
  margin: 0.7rem 4rem;
}

.infinite-scroll-component__outerdiv {
  /* max-height: 90vh;
  overflow: auto; */
}
.dealer-list a {
  color: black;
}
.dealer-list a:hover {
  text-decoration: none;
}
.dealer span {
  line-height: 1.2;
}

.map-container {
  height: 78vh;
}

@media screen and (max-width: 450px) {
  .map-container {
    height: 80vh;
  }
  .dealer-list {
    padding-left: 2rem;
  }
}
.dealer-map-link {
  color: black;
}
.dealer-map-link:hover {
  text-decoration: none;
  color: black;
}
