.top-section-cat {
  background-color: #f7f7f7;
}

.action p {
  color: #3578dc;
}
.action:hover .actions-btn {
  border: 1px solid white;
  color: white;
  background-color: #3578dc;
}
.actions-btn {
  display: flex;
  align-items: center;
  font-size: larger;
  color: #3578dc;
}
.action-icon {
  font-size: 27px;
}
.actions-btn:hover {
  text-decoration: none;
}
.location {
  cursor: pointer;
}
.address {
  line-height: 1.3;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.address:hover {
  text-decoration: none;
  color: #000;
}
