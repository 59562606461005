.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: #555;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
  background: #525252a1;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.slide-img {
  width: 100%;
  height: 400px;
  object-fit: contain;
  cursor: pointer;
}
.min-viewer {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.min-img {
  cursor: pointer;
  max-width: 100px;
}
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.6rem 1rem 0;
  transition: color 0.2s ease-in;
  background-color: #222222 !important;
  color: white;
  font-size: 2rem;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-button:hover {
  color: red;
}
.show {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  display: block;
}
.modal-img {
  max-height: 80vh;
  object-fit: contain;
  /* background-color: rgba(170, 170, 170, 0.904);
  border: 1px solid rgb(92 89 89);
  box-shadow: 2px 2px 6px 4px rgb(71, 71, 71); */
}
