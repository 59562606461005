.header {
  position: sticky;
  padding: 0.6rem 2rem;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 50px;
}
.user-name {
  font-size: 1.4rem;
  color: black;
}
.user-name::first-letter {
  text-transform: uppercase;
}
@media screen and (min-width: 578px) {
  .logo {
    height: 80px;
  }
}
