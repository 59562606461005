.product-img {
  height: 300px;
  object-fit: contain;
}
.img-placeholder {
  height: 300px;
  object-fit: cover;
}
.product-name {
  min-height: 50px;
  display: flex;
  align-items: center;
}
.product-link {
  color: black;
}
.product-link:hover {
  text-decoration: none;
  color: gray;
}
.brand {
  cursor: pointer;
}
.brand-selected {
  padding-left: 0.4rem;
  background-color: lightgray;
  border: 1px solid lightgray;
}
.brand-selected:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.brand-selected:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
