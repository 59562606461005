.email-input {
  width: 100%;
}
.result {
  position: fixed;
  top: 10px;
  z-index: 10;
  border-radius: 5px;
  transform: translate(100%);
  transition: all 1s ease;
  color: white;
  padding: 20px;
}
@media screen and (min-width: 568px) {
  .email-input {
    width: 50%;
  }
}
