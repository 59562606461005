.main {
  display: flex;
  flex-direction: row;
}
.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
}
.btn-main {
  background-color: #3578dc;
  color: white !important;
}
.btn-main svg path {
  stroke: white;
}
.btn-main:hover {
  background-color: #3d76cc;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
