.category-list {
  /* overflow: auto; */
  /* height: 90vh; */
}
.category-name {
  color: black;
}
.category-list a:hover {
  text-decoration: none;
}

.category-list .border:hover {
  outline: 2px solid lightgray;
}
