.top-section {
  background-color: #f7f7f7;
}
.top-section input:focus {
  outline: none;
}
.filter-btn {
  border-radius: 30px;
  padding: 0.4rem 1rem;
}
.filter-btn:focus {
  outline: none !important;
}
.button-border {
  border: 1px solid #dee2e6;
}
